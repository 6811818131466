import React, { useState, useRef, useMemo, useCallback } from 'react';
import mimeTypes from 'mime-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment-timezone';
import EnvelopeIcon from '@components/deprecatedTookit/icons/fa/regular/EnvelopeIcon';
import SteeringWheelIcon from '@components/deprecatedTookit/icons/fa/regular/SteeringWheelIcon';
import InfoCircleIcon from '@components/deprecatedTookit/icons/fa/regular/InfoCircleIcon';
import SyncIcon from '@components/deprecatedTookit/icons/fa/regular/SyncIcon';
import RobotIcon from '@components/deprecatedTookit/icons/fa/regular/RobotIcon';
import FileIcon from '@components/deprecatedTookit/icons/fa/regular/FileAltIcon';
import { useAppUIActionContext } from '@client/components/App/contexts';
import { toggleAppModal } from '@client/state/ui/actions';
import { logAnalyticsClick } from '@client/utils/analytics';
import { useConsigneeDeliveryPreferences } from '@client/_blessed/store/entities/consigneeDeliveryPreferences/hooks';
import { Attachment } from '@client/_blessed/components/features/Dispatch/store/attachments/model';
import Stop from '@client/_blessed/store/entities/stops/model';
import Department from '@client/_blessed/store/entities/departments/model';
import { BundleLegRelationship } from '@client/state/resources/bundle/models';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { LegStopActionShortHandEnum, TIME_ZONES } from '@drayalliance/types';
import EditableField from '../../../../components/EditableField';
import DropdownSelectInput from '../../../../components/DropdownSelectInput';
import { getDepartmentDisplayName } from '../../../../utils/data-processing/department';
import AddStopButton from '../AddStopButton';
import AddStopButtonDropdown from '../AddStopButtonDropdown/component';
import SplitLegButton from '../SplitLegButton';
import DeleteStopButton from '../DeleteStopButton';
import dateTimeRangeDisplayFormatter from '../../../../utils/date-time-range-display-formatter';
import SystemTooltip from '../../../../components/SystemTooltip';
import CopyButton from '../../../../components/CopyButton';
import ViewGateTicketModal from './components/ViewGateTicketModal';
import { selectDepartmentsById, selectStopsByLegUUID, updateLegStop } from '../../../../_blessed/store/entities';
import LegStopActionsComponent from '../LegStopActions';
import {
  selectAllLegStopActionsByUuid,
  selectAttachmentsByLegUUID
} from '../../../../_blessed/components/features/Dispatch/store';

import './style.css';

function dateTimeDisplayFormatter(value: string, timezone?: TIME_ZONES) {
  let momentValue: moment.Moment;
  if (!timezone) {
    momentValue = moment(value);
  } else {
    momentValue = moment(value).tz(timezone);
  }

  return value && momentValue.isValid() ? momentValue.format(`MM/DD HH:mm${timezone ? ' z' : ''}`) : '';
}

function deliveryNumDisplayFormatter(value: string) {
  return `${value}`;
}

function deliveryDisplayFormatter(value: Record<'startValue' | 'endValue', any>, timezone?: TIME_ZONES) {
  return `${dateTimeRangeDisplayFormatter(value, timezone)}`;
}

function pickupNumDisplayFormatter(value: string) {
  return `${value}`;
}

function pickupDisplayFormatter(value: Record<'startValue' | 'endValue', any>, timezone?: TIME_ZONES) {
  return `${dateTimeRangeDisplayFormatter(value, timezone)}`;
}

function outgatedDisplayFormatter(value: string, timezone?: TIME_ZONES) {
  return `Outgated ${dateTimeDisplayFormatter(value, timezone)}`;
}

function ingatedDisplayFormatter(value: string, timezone?: TIME_ZONES) {
  return `Ingated ${dateTimeDisplayFormatter(value, timezone)}`;
}

/*
  A few words about the oddity of the getOutgateActualTimeStamp (and related) functions:

  Historically, the stop instant associated with a terminal outgate was hard-coded to always be the
  departure SI - this was based on the phallacy that all outgates are first stops (and first stops traditionally use the departure SI).
  This is clearly not true when outgates are preceeded by a chassis split, for example.  This hard-coding meant that any time the operations team
  manually updated an outgate actual time, they were inadvertantly updating the 'wrong' SI.  This wasn't clear because the front end was also hard coded
  to source from the wrong SI.  We've now updated the backend to successfully store automated outgate times in the correct SI, so we needed to so something
  for the front end that would both reflect correctly stored outgate times and not break the pre-existing manual outgate time functionality that used the wrong SI.
  This is why the getOutgateActualTimeStamp function is so convoluted - it's a bandaid to make sure that the front end can still source the wrong SI for manual outgate times when needed

  No such update made for the getIngateActualTimeStamp function because we should never see an ingate as the first stop on a leg.

  Also worth noting that there is not convoluted logic on the getStopInstantUuid function because we want all manual updates to go to the correct SI.
*/
function getIngateActualTimeStamp(props: DispatchPageOrderLegStopInfoProps, stop: any) {
  return props.isFirstStop
    ? stop.departureStopInstant && stop.departureStopInstant.actualDateTime
    : stop.arrivalStopInstant && stop.arrivalStopInstant.actualDateTime;
}

export function getOutgateActualTimeStamp(props: DispatchPageOrderLegStopInfoProps, stop: any) {
  const preferredStopInstant = props.isFirstStop ? stop.departureStopInstant : stop.arrivalStopInstant;
  const secondaryStopInstant = props.isFirstStop ? stop.arrivalStopInstant : stop.departureStopInstant;

  if (preferredStopInstant && preferredStopInstant.actualDateTime) {
    return preferredStopInstant.actualDateTime;
  }

  if (secondaryStopInstant && secondaryStopInstant.actualDateTime) {
    return secondaryStopInstant.actualDateTime;
  }

  return null;
}

function getStopInstantUuid(props: DispatchPageOrderLegStopInfoProps, stop: any) {
  return props.isFirstStop ? stop.departureStopInstant.uuid : stop.arrivalStopInstant.uuid;
}

const generateCopyValue = (containerNumber?: string, nameOfStop?: string, appointmentTime?: string) =>
  `Container # ${containerNumber || 'n/a'}, ${nameOfStop || 'n/a'}: ${appointmentTime || 'n/a'}`;

interface DispatchPageOrderLegStopInfoProps {
  attachmentsById: Record<string, any>;
  bundle: Record<string, any>;
  className: string;
  containerNumber: string;
  disableAdd: boolean;
  disableEditStopName?: boolean;
  firstSyncEnabled: boolean;
  hasUpdates?: boolean;
  hideGateTicketModal?: boolean;
  hideStopInstants?: boolean;
  isCollapsed?: boolean;
  isDeleting?: boolean;
  isFirstOfLeg: boolean;
  isFirstStop?: boolean;
  isLastStop?: boolean;
  isLastOfLeg?: boolean;
  isSystemGeneratedAppointment: boolean;
  isTBD?: boolean;
  lastCreatedStopId: string;
  lastCreatedStopParentId: string;
  lastCreatedStopPosition: number;
  lastSyncEnabled: boolean;
  legId: string;
  locked: boolean;
  nextStop: any;
  onAddToEmailBatch: (...args: any) => any;
  onDeleteStop: (...args: any) => any;
  onDepartmentDropdownClose: (...args: any) => any;
  onDriverInstructionClick: (...args: any) => any;
  onExecuteAction: (...args: any) => any;
  onFieldChange: (...args: any) => any;
  onLegCreated: (...args: any) => any;
  onOrderUpdated: (...args: any) => any;
  onStopCreated: (...args: any) => any;
  orderId: string;
  outgateIngateEditable: boolean;
  position: number;
  prevStop?: Stop;
  showDelete?: boolean;
  showSync: boolean;
  stop: Stop;
  stopDepartments?: Department[];
  toggleSyncDialog: (...args: any) => any;
  isGrayPoolGroup?: boolean;
  graypoolContainerNumber?: string;
  nextLeg: Record<string, any> | null;
  prevLeg: Record<string, any> | null;
  bundleLegRelation: BundleLegRelationship;
  orderImportExport: string;
  fromBundleSidebar?: boolean;
}

function DispatchPageOrderLegStopInfo(props: DispatchPageOrderLegStopInfoProps) {
  const dispatch = useAppDispatch();
  const { dispatchPageLegStopActions, showTimezones } = useFlags();
  const stopsByLegUUID = useSelector(selectStopsByLegUUID);
  const attachmentsByLegUUID = useSelector(selectAttachmentsByLegUUID);
  const legStopActionsByUuid = useSelector(selectAllLegStopActionsByUuid);
  const departmentsById = useSelector(selectDepartmentsById);
  const appUIDispatch = useAppUIActionContext();
  const featFlags = useFlags();
  const [state, setState] = useState({
    stop: { ...props.stop },
    selected: false,
    isStopSelected:
      props.lastCreatedStopParentId === props.legId &&
      (props.lastCreatedStopId === props.stop.uuid || props.lastCreatedStopPosition === props.position)
  });
  const { apptOutreachEnabled: apptOutreachEnabledNextStop } = useConsigneeDeliveryPreferences({
    consigneeUuid: props.nextStop?.departmentUuid,
    importExport: props.orderImportExport
  });
  const { apptOutreachEnabled: apptOutreachEnabledThisStop } = useConsigneeDeliveryPreferences({
    consigneeUuid: props.stop.departmentUuid,
    importExport: props.orderImportExport
  });

  const bundleId = props.bundle && props.bundle.uuid;
  const stopId = state.stop.uuid;
  const { orderId } = props;
  const prevStop = useRef(props.stop);

  const stopGateTickets = useMemo(() => {
    const gateTickets: Attachment[] = [];

    const legAttachments = attachmentsByLegUUID[props.legId];

    if (!legAttachments) {
      return gateTickets;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const attachment of legAttachments) {
      if (attachment.ownerUUID === state.stop.uuid && attachment.type === 'gateticket' && !attachment.deletedAt) {
        gateTickets.push(attachment);
      }
    }

    return gateTickets;
  }, [props.attachmentsById, attachmentsByLegUUID]);

  const dropdownOptions = useMemo(
    () =>
      props.stopDepartments?.map((department) => ({
        label: getDepartmentDisplayName(department),
        id: department.uuid
      })),
    [props.stopDepartments]
  );

  const systemGenerateAppointmentDepartments = featFlags.consigneesSystemGeneratedAppointments || [];

  if (prevStop.current !== props.stop) {
    prevStop.current = props.stop;
    state.stop = props.stop;
  }
  const baseClassName = 'dispatch-page-order-leg-stop-info';
  let className = baseClassName;
  const stopInstantGroupClassName = `${baseClassName}__stop-container`;
  let apptBorderClassName = '';
  let syncBtnClassName = `${baseClassName}__sync-button`;
  let gateTicketIconClassName = `${baseClassName}__gate-ticket-icon`;

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.showSync) {
    apptBorderClassName = `${baseClassName}__stop-container-sync-border`;

    if (props.firstSyncEnabled || props.lastSyncEnabled) {
      apptBorderClassName += ` ${baseClassName}__stop-container-sync-border--enabled`;
      syncBtnClassName += ` ${baseClassName}__sync-button--enabled`;
    }
  }

  const stopInstantDepartureValueFields = [
    {
      id: state.stop.departureStopInstant.uuid,
      name: 'appointmentStart',
      resourceType: 'stopInstant',
      valueField: 'startValue'
    },
    {
      id: state.stop.departureStopInstant.uuid,
      name: 'appointmentEnd',
      resourceType: 'stopInstant',
      valueField: 'endValue'
    }
  ];

  const stopInstantArrivalValueFields = [
    {
      id: state.stop.arrivalStopInstant.uuid,
      name: 'appointmentStart',
      resourceType: 'stopInstant',
      valueField: 'startValue'
    },
    {
      id: state.stop.arrivalStopInstant.uuid,
      name: 'appointmentEnd',
      resourceType: 'stopInstant',
      valueField: 'endValue'
    }
  ];

  function handleStopChange(value: any, cProps: Record<string, any>) {
    if ((state.stop as any)[cProps.name] !== value) {
      setState((prevState) => {
        const updatedStop = { ...prevState.stop, [cProps.name]: value };

        /** Since the first & last stop of order legs are really shared, we need to keep them in sync */
        if (props.isLastOfLeg && props.nextLeg) {
          const nextLegUUID = props.nextLeg.uuid;
          dispatch(updateLegStop({ legUUID: nextLegUUID, stop: updatedStop, position: 0 }));
        } else if (props.isFirstOfLeg && props.prevLeg) {
          const prevLegUUID = props.prevLeg.uuid;
          const prevLegStopCount = stopsByLegUUID[prevLegUUID]?.length ?? 1;
          dispatch(updateLegStop({ legUUID: prevLegUUID, stop: updatedStop, position: prevLegStopCount - 1 }));
        }

        dispatch(updateLegStop({ legUUID: props.legId, stop: updatedStop, position: props.position }));

        const updatedState = { ...prevState, stop: { ...updatedStop } };
        return updatedState;
      });
    }

    props.onFieldChange(value, cProps);
  }

  const handleDropdownInputChange = (values: any[], cProps: Record<string, any>) => {
    let value = null;

    if (values[0]) {
      value = cProps.valueField ? values[0][cProps.valueField] : values[0].value;
    }

    if (!value && value !== false) {
      value = '';
    }

    handleStopChange(value === '' ? null : value, cProps);
  };

  const department = departmentsById && departmentsById[state.stop.departmentUuid];
  let opsInstruction = state.stop.opsInstructions;
  let stopInstants = null;
  const driverInstructionStopInstant = props.isFirstOfLeg
    ? state.stop.departureStopInstant
    : state.stop.arrivalStopInstant;
  let hasDefaultInstruction = false;
  if (department) {
    opsInstruction = state.stop.opsInstructions || department.opsInstructions;
    hasDefaultInstruction = props.isFirstOfLeg ? !!department.pickupInstructions : !!department.deliveryInstructions;
  }

  const driverInstruction =
    (driverInstructionStopInstant && driverInstructionStopInstant.driverInstructions) || hasDefaultInstruction;

  let departureAppointmentStart: string | null = null;
  let departureAppointmentEnd: string | null = null;

  if (state.stop.departureStopInstant) {
    departureAppointmentStart = state.stop.departureStopInstant.appointmentStart;
    departureAppointmentEnd = state.stop.departureStopInstant.appointmentEnd;
  }

  const departureValues = useMemo(
    () => ({
      startValue: departureAppointmentStart,
      endValue: departureAppointmentEnd
    }),
    [departureAppointmentStart, departureAppointmentEnd]
  );

  let arrivalAppointmentStart: string | null = null;
  let arrivalAppointmentEnd: string | null = null;

  if (state.stop.arrivalStopInstant) {
    arrivalAppointmentStart = state.stop.arrivalStopInstant.appointmentStart;
    arrivalAppointmentEnd = state.stop.arrivalStopInstant.appointmentEnd;
  }

  const arrivalValues = useMemo(
    () => ({
      startValue: arrivalAppointmentStart,
      endValue: arrivalAppointmentEnd
    }),
    [arrivalAppointmentStart, arrivalAppointmentEnd]
  );

  const timezone: TIME_ZONES | undefined = useMemo(
    () => (showTimezones ? department?.timezone : undefined),
    [showTimezones, department]
  );

  const apptTime = props.isFirstStop
    ? pickupDisplayFormatter(departureValues, timezone)
    : deliveryDisplayFormatter(arrivalValues, timezone);

  const handleSyncClick = useCallback(() => {
    if (props.toggleSyncDialog) {
      const stopInstant = props.isFirstStop ? state.stop.departureStopInstant : state.stop.arrivalStopInstant;
      props.toggleSyncDialog(stopInstant, !(props.firstSyncEnabled || props.lastSyncEnabled));
    }
  }, [
    props.toggleSyncDialog,
    state.stop.arrivalStopInstant,
    state.stop.departureStopInstant,
    props.firstSyncEnabled,
    props.lastSyncEnabled
  ]);

  let showReqApptDelBtn = false;
  if (
    props.prevStop &&
    props.stop &&
    props.prevStop.type === 'terminal' &&
    props.stop.type === 'consignee' &&
    apptOutreachEnabledThisStop
  ) {
    const terminalSIMoment =
      moment(props.prevStop.departureStopInstant.appointmentStart).isValid() &&
      moment(props.prevStop.departureStopInstant.appointmentStart);

    const consigneeSIMoment =
      moment(props.stop.arrivalStopInstant.appointmentStart).isValid() &&
      moment(props.stop.arrivalStopInstant.appointmentStart);

    /**
     * If there is no consignee appt time set, but there is a terminal appt then always show the request del appt button
     *   Else make sure that terminal appt is before the consignee in order to show the request del appt button
     */
    if (!consigneeSIMoment && moment.isMoment(terminalSIMoment) && moment().isBefore(terminalSIMoment)) {
      showReqApptDelBtn = true;
    } else {
      showReqApptDelBtn =
        moment.isMoment(terminalSIMoment) &&
        moment.isMoment(consigneeSIMoment) &&
        terminalSIMoment.isAfter(consigneeSIMoment) &&
        moment().isBefore(terminalSIMoment);
    }
  }

  if (department) {
    // Stop instants
    const delPickStopInstants = props.isFirstStop ? (
      <>
        <div style={{ display: 'flex' }}>
          <EditableField
            className={`${baseClassName}__editable-field`}
            type="text"
            // importantText="PICK #"
            placeholder="Appt #"
            editable
            displayFormatter={pickupNumDisplayFormatter}
            id={state.stop.departureStopInstant.uuid}
            name="appointmentNumber"
            resourceType="stopInstant"
            value={state.stop.departureStopInstant && state.stop.departureStopInstant.appointmentNumber}
            onSave={props.onFieldChange}
          />
          {props.showSync && <SyncIcon className={syncBtnClassName} onClick={handleSyncClick} />}
        </div>
        <EditableField
          className={`${baseClassName}__editable-field`}
          type="date-time-range"
          timezone={timezone}
          // importantText="PICK"
          placeholder="Appt"
          editable
          displayFormatter={(value: any) => pickupDisplayFormatter(value, timezone)}
          id={state.stop.departureStopInstant.uuid}
          name="appointmentStart"
          resourceType="stopInstant"
          valueFields={stopInstantDepartureValueFields}
          value={departureValues}
          inputProps={{
            ranged: true
          }}
          onSave={(value: Record<'startValue' | 'endValue', any>, cProps: Record<string, any>) => {
            if (props.onFieldChange) {
              props.onFieldChange(value.startValue, cProps.valueFields[0]);
              props.onFieldChange(value.endValue, cProps.valueFields[1]);
            }

            if (
              value.endValue &&
              props.stop &&
              props.nextStop &&
              props.stop.type === 'terminal' &&
              props.nextStop.type === 'consignee' &&
              !systemGenerateAppointmentDepartments.includes(props.nextStop.departmentUuid) // mimic props.isSystemGeneratedAppointment
            ) {
              props.onAddToEmailBatch({
                apptEndTime: value.endValue,
                orderId: props.orderId,
                legId: props.legId,
                terminalStop: state.stop,
                consigneeStop: props.nextStop,
                appointmentType: 'directDelivery',
                apptOutreachEnabled: apptOutreachEnabledNextStop
              });
            }
          }}
        />
      </>
    ) : (
      <>
        <div style={{ display: 'flex' }}>
          <EditableField
            className={`${baseClassName}__editable-field `}
            type="text"
            placeholder="Appt #"
            editable
            displayFormatter={deliveryNumDisplayFormatter}
            id={state.stop.arrivalStopInstant.uuid}
            name="appointmentNumber"
            resourceType="stopInstant"
            value={state.stop.arrivalStopInstant && state.stop.arrivalStopInstant.appointmentNumber}
            onSave={props.onFieldChange}
          />
          {props.showSync && <SyncIcon className={syncBtnClassName} onClick={handleSyncClick} />}
        </div>
        <EditableField
          className={`${baseClassName}__editable-field`}
          type="date-time-range"
          placeholder="Appt"
          editable
          displayFormatter={(value: any) => deliveryDisplayFormatter(value, timezone)}
          id={state.stop.arrivalStopInstant.uuid}
          name="appointmentStart"
          resourceType="stopInstant"
          valueFields={stopInstantArrivalValueFields}
          value={arrivalValues}
          onChange={props.onFieldChange}
          inputProps={{
            ranged: true
          }}
          timezone={timezone}
        />
        {props.isSystemGeneratedAppointment && (
          <div className={`${baseClassName}__robot-container`}>
            <RobotIcon className={`${baseClassName}__robot-icon`} /> Auto Appt On
            {/* <SystemTooltip id="auto-appointment-robot" items={[{ message: 'System will generate appointment' }]} /> */}
          </div>
        )}
        {showReqApptDelBtn && !props.isSystemGeneratedAppointment && (
          <div
            className={`${baseClassName}__request-del-appt-btn-container`}
            onClick={() => {
              props.onAddToEmailBatch({
                apptEndTime: null,
                orderId: props.orderId,
                legId: props.legId,
                terminalStop: props.prevStop,
                consigneeStop: props.stop,
                respectSaveQueue: false,
                appointmentType: 'directDelivery',
                apptOutreachEnabled: apptOutreachEnabledThisStop
              });
            }}
          >
            <EnvelopeIcon className={`${baseClassName}__instruction-indicator`} />
            Request Del Appt
          </div>
        )}
      </>
    );

    const stopLegStopAction = legStopActionsByUuid[props.stop.legStopActionUuid];
    if (department.type === 'terminal' && stopLegStopAction) {
      const outgateFieldComponent = (
        <EditableField
          className={`${baseClassName}__editable-field ${baseClassName}__outgate-ingate-text`}
          type="date-time"
          placeholder="Outgate"
          editable={props.outgateIngateEditable}
          displayFormatter={(value: string) => outgatedDisplayFormatter(value, timezone)}
          id={getStopInstantUuid(props, state.stop)}
          name="actualDateTime"
          resourceType="stopInstant"
          value={getOutgateActualTimeStamp(props, state.stop)}
          onChange={props.onFieldChange}
          timezone={timezone}
        />
      );

      const ingateFieldComponent = (
        <EditableField
          className={`${baseClassName}__editable-field ${baseClassName}__outgate-ingate-text`}
          type="date-time"
          placeholder="Ingate"
          editable={props.outgateIngateEditable}
          displayFormatter={(value: string) => ingatedDisplayFormatter(value, timezone)}
          id={getStopInstantUuid(props, state.stop)}
          name="actualDateTime"
          resourceType="stopInstant"
          value={getIngateActualTimeStamp(props, state.stop)}
          onChange={props.onFieldChange}
          timezone={timezone}
        />
      );

      let outgateIngateFieldComponents;
      switch (stopLegStopAction.shortHand) {
        case LegStopActionShortHandEnum.PU_L:
        case LegStopActionShortHandEnum.PU_LC:
        case LegStopActionShortHandEnum.PU_E:
        case LegStopActionShortHandEnum.PU_EC:
          outgateIngateFieldComponents = outgateFieldComponent;
          break;
        case LegStopActionShortHandEnum.DO_E:
        case LegStopActionShortHandEnum.DO_EC:
        case LegStopActionShortHandEnum.DO_L:
        case LegStopActionShortHandEnum.DO_LC:
          outgateIngateFieldComponents = ingateFieldComponent;
          break;
        default:
          break;
      }

      stopInstants = (
        <>
          <div className={`${stopInstantGroupClassName} ${apptBorderClassName}`}>{delPickStopInstants}</div>
          {outgateIngateFieldComponents}
        </>
      );
    } else {
      stopInstants = <div className={`${stopInstantGroupClassName} ${apptBorderClassName}`}>{delPickStopInstants}</div>;
    }
  }

  const showAddStop = !props.locked;
  const showDeleteStop = !props.locked;
  const canDeleteStop = props.showDelete && !props.isDeleting;
  const canAddStop = !props.disableAdd;
  const canSplitLeg = !props.isLastOfLeg && !props.hasUpdates && canAddStop;
  const showAddLeg = !props.locked;
  const showGateTicketIcon = !props.hideGateTicketModal && props.stop.type === 'terminal';
  let gateTicket: Attachment | undefined;
  if (showGateTicketIcon && stopGateTickets.length) {
    gateTicket = stopGateTickets?.[0];
    gateTicketIconClassName += ` ${baseClassName}__gate-ticket-icon--enabled`;
  }
  const hasGateTicket = !!gateTicket;
  const dropdownValues = department
    ? [{ id: department.uuid, label: getDepartmentDisplayName(department) }]
    : [{ label: null, id: null }];
  const disableEditStopName = props.locked || props.disableEditStopName;
  const stopName = props.isTBD
    ? 'TBD'
    : dropdownValues[0].label ||
      (dropdownValues[0].id && getDepartmentDisplayName(departmentsById[dropdownValues[0].id])) ||
      '';

  const handleOpenGateTicketModal = () => {
    logAnalyticsClick('open gate ticket modal', 'dispatch', { legId: props.legId });
    appUIDispatch(
      toggleAppModal(
        true,
        <ViewGateTicketModal
          attachmentId={gateTicket!.uuid}
          mimeTypes={mimeTypes}
          terminalName={getDepartmentDisplayName(department) || ''}
          timezone={timezone}
          gateType={props.isFirstStop ? 'outgate' : 'ingate'}
          stopInstant={props.isFirstStop ? state.stop.departureStopInstant : state.stop.arrivalStopInstant}
          containerNumber={props.isGrayPoolGroup ? props.graypoolContainerNumber : props.containerNumber}
          onCancel={() => appUIDispatch(toggleAppModal(false))}
          onConfirm={(val: string) => {
            const dataSet = {
              id: props.isFirstStop ? state.stop.departureStopInstant.uuid : state.stop.arrivalStopInstant.uuid,
              resourceType: 'stopInstant',
              name: 'actualDateTime'
            };
            setState((prevState) => {
              const updatedState = { ...prevState };
              if (props.isFirstStop) {
                updatedState.stop.departureStopInstant = {
                  ...updatedState.stop.departureStopInstant,
                  actualDateTime: val
                };
              } else {
                updatedState.stop.arrivalStopInstant = {
                  ...updatedState.stop.arrivalStopInstant,
                  actualDateTime: val
                };
              }

              return updatedState;
            });
            props.onFieldChange(val, dataSet);
          }}
        />
      )
    );
  };

  return (
    <div className={className}>
      <div className={`${baseClassName}__inputs`} data-tooltip-id={`stop-name-${props.stop.uuid}`}>
        <CopyButton
          value={generateCopyValue(
            props.isGrayPoolGroup ? props.graypoolContainerNumber : props.containerNumber,
            stopName,
            apptTime
          )}
          className={`${baseClassName}__cpy-btn`}
        />

        {!disableEditStopName ? (
          // manually immitating editable field here due to page jump issue after search and option select.
          // seems to be a problem with the keepOpen prop within the editable field since it's always enabled.
          // there's probably a workaround within the editable field triyng to using the dropdown callbacks for onclose events
          // and implementing the same onBlur code as below instead of using the outerclick hoc component but this works for now.
          <DropdownSelectInput
            className={`${baseClassName}__location-info-name`}
            maxListLen={60}
            autoFocus={state.isStopSelected}
            autoSelect
            dropdownHandle={false}
            keepOpen={state.isStopSelected}
            values={dropdownValues}
            placeholder="Department"
            options={dropdownOptions}
            labelField="label"
            valueField="id"
            id={stopId}
            name="departmentUuid"
            resourceType="stop"
            additionalProps={{
              onBlur: () => {
                setState((prevState) => ({
                  ...prevState,
                  isStopSelected: false
                }));
              }
            }}
            onChange={handleDropdownInputChange}
            onDropdownClose={props.onDepartmentDropdownClose || null}
          />
        ) : (
          <span className={`${baseClassName}__display-field`}>{stopName}</span>
        )}
      </div>
      <SystemTooltip id={`stop-name-${props.stop.uuid}`} items={[{ message: stopName }]} />

      {!props.isCollapsed && (
        <>
          <div className={`${baseClassName}__actions`}>
            {showDeleteStop && (
              <DeleteStopButton
                disabled={!canDeleteStop}
                onDelete={() => {
                  logAnalyticsClick('delete stop', 'dispatch');
                  if (props.onDeleteStop) {
                    props.onDeleteStop(props.legId, props.stop.uuid);
                  }
                }}
              />
            )}
            {showAddStop &&
              (dispatchPageLegStopActions ? (
                <AddStopButtonDropdown
                  disabled={!canAddStop}
                  legId={props.legId}
                  stop={props.stop}
                  position={props.position}
                  isFirstStop={props.isFirstStop}
                  isLastStop={props.isLastStop}
                  isFirstLeg={!props.prevLeg}
                  isLastLeg={!props.nextLeg}
                  onExecuteAction={props.onExecuteAction}
                  onStopCreated={props.onStopCreated || null}
                  bundleLegRelation={props.bundleLegRelation}
                />
              ) : (
                <AddStopButton
                  disabled={!canAddStop}
                  legId={props.legId}
                  position={props.position}
                  isLastOfLeg={!!props.isLastOfLeg}
                  onExecuteAction={props.onExecuteAction}
                  onStopCreated={props.onStopCreated || null}
                />
              ))}
            {showAddLeg && (
              <SplitLegButton
                disabled={!canSplitLeg}
                orderID={orderId}
                legID={props.legId}
                stopID={stopId}
                bundleID={bundleId}
                currentStopPostion={props.position}
                nextStop={props.nextStop}
                onExecuteAction={props.onExecuteAction}
                onLegCreated={props.onLegCreated || null}
                onOrderUpdated={props.onOrderUpdated}
                orderImportExport={props.orderImportExport}
              />
            )}
            <div className={`${baseClassName}__actions ${baseClassName}__actions-right`}>
              {!props.locked && driverInstruction && (
                <>
                  <SteeringWheelIcon
                    className={`${baseClassName}__instruction-indicator`}
                    data-tooltip-id="driver-instruct-icon"
                    onClick={props.onDriverInstructionClick || null}
                  />
                  <SystemTooltip id="driver-instruct-icon" items={[{ message: 'Driver Instruction' }]} />
                </>
              )}
              {!props.locked && opsInstruction && (
                <>
                  <InfoCircleIcon
                    className={`${baseClassName}__instruction-indicator`}
                    data-tooltip-id="ops-instruct-icon"
                    onClick={props.onDriverInstructionClick || null}
                  />
                  <SystemTooltip id="ops-instruct-icon" items={[{ message: 'Ops Instruction' }]} />
                </>
              )}
              {showGateTicketIcon && (
                <>
                  <FileIcon
                    className={gateTicketIconClassName}
                    data-tooltip-id="gate-ticket-modal-icon"
                    onClick={hasGateTicket ? handleOpenGateTicketModal : null}
                  />
                  <SystemTooltip id="gate-ticket-modal-icon" items={[{ message: 'View Gate Ticket' }]} />
                </>
              )}
            </div>
          </div>
          {dispatchPageLegStopActions && (
            <LegStopActionsComponent
              stop={props.stop}
              onFieldChange={props.onFieldChange}
              fromBundleSidebar={props.fromBundleSidebar}
            />
          )}
          {!props.hideStopInstants && <div className={`${baseClassName}__times`}>{stopInstants}</div>}
        </>
      )}
    </div>
  );
}

export default DispatchPageOrderLegStopInfo;

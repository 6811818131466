import { useState, useRef, useContext, useEffect } from 'react';
import { fetchConsigneeDeliveryPreferencesByDepartmentAndType } from '@client/_blessed/store/entities';
import Stop from '@client/_blessed/store/entities/stops/model';
import { BundleLegRelationship } from '@client/state/resources/bundle/models';
import Department from '@client/_blessed/store/entities/departments/model';
import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import { LegJsonApiItem } from '@drayalliance/types';
import { appUIActionContext, appUIStateContext, appStateContext } from '../../../../components/App/contexts';
import { openRightSidebar } from '../../../../state/ui/actions';
import DepartmentDetailSidebar from '../../../../components/DepartmentDetailSidebar/component';
import DispatchPageOrderLegStopInfo from '../DispatchPageOrderLegStopInfo';
import StopProgressBar from '../StopProgressBar';

import './style.css';

interface DispatchPageOrderLegStopProps {
  containerNumber: string;
  disableAdd: boolean;
  hasUpdates?: boolean;
  outgateIngateEditable: boolean;
  locked: boolean;
  progressNotifications: any[] | null;
  stopDepartments?: Department[];
  attachmentsById: Record<string, any>;
  departmentsById?: Record<string, Department>;
  bundle: any;
  stop: Stop;
  showDelete?: boolean;
  isDeleting?: boolean;
  isFirstStop?: boolean;
  isLastStop?: boolean;
  isFirstOfLeg: boolean;
  isLastOfLeg?: boolean;
  status: string | null;
  orderId: string;
  orderImportExport: string;
  // eslint-disable-next-line no-unused-vars
  onDelete: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onFieldChange: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  onOrderUpdated: (...args: any) => any;
  isCollapsed?: boolean;
  legId: string;
  position: number;
  // eslint-disable-next-line no-unused-vars
  onExecuteAction: (...args: any) => any;
  // eslint-disable-next-line no-unused-vars
  toggleSyncDialog: (...args: any) => any;
  showSync: boolean;
  firstSyncEnabled: boolean;
  lastSyncEnabled: boolean;
  disableEditStopName?: boolean;
  nextStop: any;
  hideStopInstants?: boolean;
  isTBD?: boolean;
  isSystemGeneratedAppointment: boolean;
  onAddToEmailBatch: (...args: any) => any;
  prevStop?: Stop;
  hideGateTicketModal?: boolean;
  isGrayPoolGroup?: boolean;
  graypoolContainerNumber?: string;
  nextLeg: LegJsonApiItem | null;
  prevLeg: LegJsonApiItem | null;
  leg: Record<string, any>;
  bundleLegRelation: BundleLegRelationship;
  fromBundleSidebar?: boolean;
}

function DispatchPageOrderLegStop(props: DispatchPageOrderLegStopProps) {
  const dispatch = useAppDispatch();
  const executeUiAction = useContext(appUIActionContext);
  const appUIState = useContext(appUIStateContext);
  const appState = useContext(appStateContext);
  const [state, setState] = useState({
    stop: props.stop,
    selected: false
  });
  const { lastCreatedStopId } = appState;
  const { lastCreatedStopParentId } = appState;
  const { lastCreatedStopPosition } = appState;

  const stopId = state.stop.uuid;
  const { orderId } = props;

  useEffect(() => {
    if (props.stop.type === 'consignee') {
      const type = props.orderImportExport === 'export' ? 'export' : 'import';
      dispatch(
        fetchConsigneeDeliveryPreferencesByDepartmentAndType({ departmentUuid: props.stop.departmentUuid, type })
      );
    }
  }, []);

  if (state.selected) {
    state.selected = appUIState['ui.rightSidebar.open'];
  }

  const prevStop = useRef(props.stop);

  if (prevStop.current !== props.stop) {
    prevStop.current = props.stop;
    state.stop = props.stop;
  }
  const baseClassName = 'dispatch-page-order-leg-stop';
  const className = baseClassName;
  const stopInfoClassName = `${baseClassName}__stop-info`;

  const stopInstantDeparture = state.stop.departureStopInstant;
  const stopInstantArrival = state.stop.arrivalStopInstant;

  const handleLegCreated = () => {
    if (props.onOrderUpdated) {
      props.onOrderUpdated();
    }
  };

  const handleStopCreated = () => {
    if (props.onOrderUpdated) {
      props.onOrderUpdated();
    }
  };

  const handleDeleteStop = (legId: string, stopIdToDelete: any) => props.onDelete(legId, stopIdToDelete);

  const stopDepartment = props.departmentsById && props.departmentsById[state.stop.departmentUuid];

  const openDepartment = (tab: any) => {
    if (stopDepartment) {
      setState({
        ...state,
        selected: true
      });

      executeUiAction(
        openRightSidebar(
          <DepartmentDetailSidebar
            departmentId={stopDepartment.uuid}
            title="Stop"
            stopInstant={props.isFirstOfLeg ? stopInstantDeparture : stopInstantArrival}
            selectedTab={tab || 'info'}
            onOrderUpdated={props.onOrderUpdated}
            stop={props.stop}
            legId={props.legId}
            leg={props.leg}
            orderId={orderId}
            optimisticallyRemoveDepartment={() => {}}
            canDelete={false}
            editable={false}
          />
        )
      );
    }
  };

  const handleDriverInstructionClick = () => {
    openDepartment('sop');
  };

  const handleDepartmentDropdownClose = () => {
    appState.lastCreatedStopId = undefined;
    appState.lastCreatedStopParentId = undefined;
  };

  const handleStopClick = () => {
    openDepartment('sop');
  };

  let departmentType = stopDepartment && stopDepartment.type;

  if (props.isTBD) {
    departmentType = 'terminal';
  }

  return (
    <div className={className}>
      <StopProgressBar
        progressNotifications={props.progressNotifications}
        departmentType={departmentType}
        stopId={stopId}
        isLastStop={props.isLastStop}
        isFirstOfLeg={props.isFirstOfLeg}
        isSelected={state.selected}
        status={props.status}
        onStopClick={(!props.isTBD && handleStopClick) || null}
        locked={false}
      />
      <DispatchPageOrderLegStopInfo
        isTBD={props.isTBD}
        isSystemGeneratedAppointment={props.isSystemGeneratedAppointment}
        className={stopInfoClassName}
        isCollapsed={props.isCollapsed}
        disableAdd={props.disableAdd}
        hasUpdates={props.hasUpdates}
        outgateIngateEditable={props.outgateIngateEditable}
        locked={props.locked}
        attachmentsById={props.attachmentsById}
        stopDepartments={props.stopDepartments}
        bundle={props.bundle}
        stop={props.stop}
        showDelete={props.showDelete}
        isDeleting={props.isDeleting}
        isFirstStop={props.isFirstStop}
        isLastStop={props.isLastStop}
        isFirstOfLeg={props.isFirstOfLeg}
        isLastOfLeg={props.isLastOfLeg}
        containerNumber={props.containerNumber}
        orderId={orderId}
        orderImportExport={props.orderImportExport}
        lastCreatedStopId={lastCreatedStopId}
        lastCreatedStopParentId={lastCreatedStopParentId}
        lastCreatedStopPosition={lastCreatedStopPosition}
        onFieldChange={props.onFieldChange}
        legId={props.legId}
        position={props.position}
        onExecuteAction={props.onExecuteAction}
        onDriverInstructionClick={handleDriverInstructionClick}
        onDepartmentDropdownClose={handleDepartmentDropdownClose}
        onLegCreated={handleLegCreated}
        onStopCreated={handleStopCreated}
        onDeleteStop={handleDeleteStop}
        onAddToEmailBatch={props.onAddToEmailBatch}
        prevStop={props.prevStop}
        toggleSyncDialog={props.toggleSyncDialog}
        showSync={props.showSync}
        firstSyncEnabled={props.firstSyncEnabled}
        lastSyncEnabled={props.lastSyncEnabled}
        disableEditStopName={props.disableEditStopName}
        nextStop={props.nextStop}
        hideStopInstants={props.hideStopInstants}
        onOrderUpdated={props.onOrderUpdated}
        hideGateTicketModal={props.hideGateTicketModal}
        isGrayPoolGroup={props.isGrayPoolGroup}
        graypoolContainerNumber={props.graypoolContainerNumber}
        nextLeg={props.nextLeg}
        prevLeg={props.prevLeg}
        bundleLegRelation={props.bundleLegRelation}
        fromBundleSidebar={props.fromBundleSidebar}
      />
    </div>
  );
}

export default DispatchPageOrderLegStop;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PerDiemPage from '@client/_blessed/components/features/ArAp/features/PerDiemDigest';
import BulkInvoiceGenerationPage from '@client/_blessed/components/features/ArAp/features/BulkInvoiceGeneration/component';
import WaitingForPaymentPage from '../../pages/WaitingForPaymentPage';
import ShipperInvoice from '../../_blessed/components/features/ArAp/features/ShipperInvoice';
import ShipperInvoiceV2 from '../../_blessed/components/features/ArAp/features/ShipperInvoiceV2';

import './style.css';

function ArApLayout(props) {
  useEffect(() => {
    if (props.onToggleLeftSiderbar) {
      props.onToggleLeftSiderbar(true);
    }

    return () => {
      if (props.onToggleLeftSiderbar) {
        props.onToggleLeftSiderbar(false);
      }
    };
  }, []);

  const { grow544: sendInvoiceV2 } = useFlags();

  return (
    <>
      <Helmet title="AR/AP" />
      <Switch>
        <Route exact path={`${props.match.path}/waiting-for-payment`} component={WaitingForPaymentPage} />
        <Route
          exact
          path={`${props.match.path}/shipper-invoice`}
          component={sendInvoiceV2 ? ShipperInvoiceV2 : ShipperInvoice}
        />
        <Route exact path={`${props.match.path}/bulk-invoice-generation`} component={BulkInvoiceGenerationPage} />
        <Route exact path={`${props.match.path}/per-diem/actual-per-diem`} component={PerDiemPage} />
      </Switch>
    </>
  );
}

ArApLayout.propTypes = {
  match: PropTypes.object,
  onToggleLeftSiderbar: PropTypes.func
};

export default React.memo(ArApLayout);
